
import * as d from './proxies';

export const DIRECTIVES = [
  d.PfButton,
  d.PfCheckbox,
  d.PfDropdown,
  d.PfImage,
  d.PfPhoneInput,
  d.PfSelect
];
